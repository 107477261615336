<!--
 * @Author: your name
 * @Date: 2020-12-23 15:49:19
 * @LastEditTime: 2022-03-23 11:42:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory_vue/src/views/user/components/ScoreCard.vue
-->
<template>
  <div class="item text-ct"  :style="itemStyle">
		<div class="font-16 font-weight">趣学术账号安全指数</div>
		<div class="font-desc">
			{{userScoreData.desc}}
			<i class="el-icon-question pointer" @click="onJumpHelp" v-if="userScoreData.id<3"></i>
		</div>
		<canvas :id="canvasName" class="canvas" ref="canvasRef" width="210" height="130"></canvas>
	</div>
</template>
<script>
import {getToken} from '@/utils/auth'

export default {
	name: 'ScoreCard',
	props:['score','time'],
  data() {
    return {
	    scoreData:[
				{min:0,max:64,bgImage:require('@/assets/user/bg_account_danger.png'),title:'较高风险',desc:'账号存在较高风险，建议立即绑定手机号和微信号',id:1},
				{min:65,max:79,bgImage:require('@/assets/user/bg_account_danger.png'),title:'存在风险',desc:'账号存在风险，建议完善账号安全相关设置',id:2},
				{min:80,max:89,bgImage:require('@/assets/user/bg_account_safe.png'),title:'安全度较高',desc:'账号安全防护中',id:3},
				{min:90,max:100,bgImage:require('@/assets/user/bg_account_safe.png'),title:'安全度高',desc:'账号安全防护中',id:4},
			],
			canvasName:'canvas',
			scoreValue:0,
			timer:null
    }
  },
  components: {
    
  },
  computed: {
		userScoreData(){
			let obj = {}
			this.scoreData.map(item=>{
				if(this.score>=item.min&&this.score<=item.max){
					obj = item
				}
			})
			return obj
		},
		itemStyle(){
			return {
				'background-image':`url(${this.userScoreData.bgImage})`,
				'background-repeat':'no-repat',
				'background-size':'100% 100%'
			}
		}
  },
  methods: {
		onJumpHelp(){
			let url = this.$router.resolve(`/help/detail/6f5e18a547a0c01aa662dfc2a43854bc`)
    	window.open(url.href, '_blank')
		},
		drawCir(){
			let c = document.getElementById(this.canvasName),
					ctx=c.getContext("2d"),
					cWidth = c.width,
					cHeight = c.height
			ctx.clearRect(0,0,cWidth,cHeight)
			// 虚线内圆
			ctx.setLineDash([2,2]);
			ctx.strokeStyle = '#fff'; 
			ctx.beginPath(); 
			ctx.arc(105,106,90,33/36*Math.PI,75/36*Math.PI,false)
			ctx.stroke()
			// 实线外底色圆
			ctx.setLineDash([])
			ctx.strokeStyle = '#fff'; 
			ctx.lineWidth = 2
			ctx.globalAlpha = 0.5
			ctx.beginPath(); 
			ctx.arc(105,106,100,33/36*Math.PI,75/36*Math.PI,false)
			ctx.stroke()
			// 实线外圆
			ctx.globalAlpha = 1
			ctx.beginPath(); 
			let endCir = 33/36*Math.PI+(42/36*Math.PI)*(this.scoreValue/100)
			ctx.arc(105,106,100,33/36*Math.PI,endCir,false)
			ctx.stroke()
			// 结尾的圆头
			let angle = 2*Math.PI - endCir
			let xPos = Math.cos(angle)*100 + cWidth/2
			let yPos = -Math.sin(angle)*100 + cWidth/2
			ctx.beginPath()
			ctx.arc(xPos,yPos+2,4,0,2*Math.PI,false)
			ctx.fillStyle = '#fff'
			ctx.fill()
			ctx.closePath()
			ctx.stroke()
			// 文案
			ctx.fillStyle = '#fff'
			ctx.font = "14px Arial"
			ctx.textAlign = 'center'
			ctx.fillText(this.userScoreData.title,105,60)
			// 进度
			ctx.fillStyle = '#fff'
			ctx.font = "bold 38px Arial"
			ctx.textAlign = 'center'
			ctx.fillText(this.scoreValue,105,100)
			// 时间
			ctx.fillStyle = '#fff'
			ctx.font = "10px Arial"
			ctx.textAlign = 'center'
			ctx.fillText(`评估时间：${this.time}`,105,117)
		},
		draw(){
			this.timer = setInterval(()=>{
				if(this.scoreValue < this.score){
					this.scoreValue += 1
					this.drawCir()
				}else{
					if(this.score==0) this.drawCir()
					this.timer && clearInterval(this.timer)
				}
			},10)
		}
  },
  mounted () {
		this.draw()
	},
	watch:{
		score(newVal,preVal){
			if(newVal!=preVal){
				this.draw()
			}
		}
	}
  
}
</script>
<style lang="scss" scoped>
  .item {
    height: 100%;
    border-radius: 8px;
    color: white;
    position: relative;
    .font-16 {
      font-size: 16px;
      padding-top: 50px;
      margin-bottom: 6px;
    }
    .canvas {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
</style>