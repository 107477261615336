<template>
  <div class="container">
    <div class="common-padding flex">
      <div class="font-size-large font-weight"><span class="safety">账号安全</span>-更换微信号</div>
      <div class="back" @click="onBack">
        <img class="back-img" src="../../assets/user/icon_back.png" alt="">返回上一级
      </div>
    </div>
    <el-card class="card">
      <div class="content">
        <div class="step">
          <div class="steps step-one"></div>
          <div class="steps step-two" :class="{'this-step': step != 1}"></div>
          <div class="steps step-three" :class="{'this-step': (step > 2)}"></div>
          <div class="steps step-four" :class="{'this-step': step === 4}"></div>
        </div>
        <div class="tips">
          <div class="tip one-tip">1.验证手机号</div>
          <div class="tip" :class="{'one-tip': step != 1}">2.绑定新微信</div>
          <div class="tip" :class="{'one-tip': step > 2}">3.确认信息</div>
          <div class="tip" :class="{'one-tip': step === 4}">4.完成更换</div>
        </div>
        <div class="scan-box flex flex-column vertical-center" v-if="step === 1">
          <el-form label-width="120px" :model="checkPhone" :rules="checkPhoneRules" ref="checkPhone">
            <el-form-item label="已绑定手机号" class="mobile" prop="mobile">
              <el-input v-model="checkPhone.mobile" maxlength='11' minlength='11'></el-input>
            </el-form-item>
            <div class="inline" :inline="true">
              <el-form-item label="验证码" prop="code">
                <el-input v-model="checkPhone.code" class="code"></el-input>
                <el-button type="primary" v-if='countDownTime == 0' @click="sendSms" class="get-code"
                           :class="{'disable': !isPhoneNumber && isSend }">{{ isSend ? '发送中' : '获取验证码' }}
                </el-button>
                <el-button type="primary" class="get-code" v-else>{{ countDownTime }}s重新获取</el-button>
                <div class="voice-code" @click='sendVoice' v-show='showVoice'>收不到验证码？试试语音验证</div>
              </el-form-item>
            </div>
            <el-form-item>
              <el-button type="primary" @click="submitForm('checkPhone')" class="bind">验证手机号</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="scan-box flex flex-column vertical-center" v-if="step === 2">
          <div class="scan-image flex level-center pointer" @click="initScan">
            <img :src="wxCodeImage.qrCodeUrl" alt="">
          </div>
          <div class="font-size-normal">请使用微信扫描二维码进行绑定</div>
        </div>
        <div class="scan-box vertical-center" v-if="step === 3">
          <div class="sure-bind flex level-center">
            <div class="fac-image">
              <img src="@/assets/user/icon_default.png" alt="">
              <div class="factory-name">厂家：{{ userInfo.orgName }}<br>
                <span>名称：{{ userInfo.name }} <br>账号：{{ userInfo.login }}</span></div>
            </div>
            <img src="../../assets/user/icon_bind.png" alt="" class="img-bind">
            <div class="wx-image">
              <img :src="isWxAvatar" alt="">
              <div class="desc-two">昵称：{{ wxName }}</div>
            </div>
          </div>
          <div class="flex level-center vertical-center scan-icon-box">
            <div class="flex vertical-center">
              <el-button type="primary" class="back-step next-step" @click="goBack">使用其他微信号</el-button>
              <el-button type="primary" class="next-step" @click="submitBindWx">确认绑定</el-button>
            </div>
          </div>
        </div>
        <div class="scan-box flex flex-column vertical-center" v-if="step === 4">
          <img src="../../assets/user/icon_success.png" alt="" class="success-img">
          <div class="font-size-normal">微信号{{ wxName }}与该账号绑定成功<br>下次可用该微信扫码直接登录该账号</div>
          <div class="flex level-center vertical-center scan-icon-box">
            <div class="flex vertical-center">
              <el-button type="primary" class="next-step" @click="finish">完成</el-button>
            </div>
          </div>
        </div>
        <el-dialog
          :visible.sync="scanDialogVisible"
          width="25%"
          :center='true'
          :show-close='false'
          top='30vh'
          class='dialog-box'
        >
          <div class="flex level-center">
            <img src="@/assets/common/icon_failed.png" alt="">
          </div>
          <div class="text-ct margin-t25">{{ scanDialogMsg }}</div>
          <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeScanErrorModal">确定</el-button>
      </span>
        </el-dialog>
        <el-dialog
          width="25%"
          :visible.sync="failDialogVisible"
          :center='true'
          :show-close='false'
          top='30vh'
          class='dialog-box'
        >
          <div class="flex level-center">
            <img src="@/assets/common/icon_failed.png" alt="">
          </div>
          <div class="text-ct margin-t25">{{ passwordErrorModalMsg }}</div>
          <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onCloseFailDialogVisible">我知道了</el-button>
      </span>
        </el-dialog>
      </div>
    </el-card>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { initTCaptcha } from "@/utils/util";
import { getUserInfo, getPersonInfo, setPersonInfo } from "@/utils/auth";

export default {
  name: "ChangeWechat",
  data() {
    return {
      step: 1,
      checkPhone: {
        mobile: '',
        code: ''
      },
      checkPhoneRules: {
        mobile: [
          { required: true, message: '请填写手机号', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请填写验证码', trigger: 'blur' }
        ],
      },
      failDialogVisible: false,
      successDialogVisible: false,
      isSend: false,
      isVoice: false,
      countDownTime: 0,
      showVoice: false,
      smsTimer: null,
      scanTimer: null,//扫码登录查询结果定时器
      scanDialogVisible: false,//扫码结果失败弹窗
      scanDialogMsg: '',//扫码失败弹窗
      isReqScanResult: false,//扫码结果查询限制
      personInfo: '',
      userInfo: '',
      wxAvatar: '',
      wxName: '',
      passwordErrorModalMsg: '',
    }
  },
  computed: {
    ...mapGetters(['captchaAppId', 'wxCodeImage']),
    isPhoneNumber() {
      return /^\d{11}$/gi.test(this.checkPhone.mobile)
    },
    isWxAvatar() {
      return this.wxAvatar ? this.wxAvatar : require('@/assets/user/icon_default.png')
    }
  },
  components: {},
  methods: {
    ...mapActions(['getWxCodeImage', 'checkWxCodeResult', 'getSmsCode', 'checkCaptcha', 'paymentCheckValue', 'bindWechat']),
    onBack() {
      this.$router.go(-1)
    },
    onCloseSuccessDialogVisible() {
      this.successDialogVisible = false
      this.$router.push({ name: 'UserLogin' })
    },
    sendSms(data) {
      if (!this.checkPhone.mobile)
        return
      if (!this.isPhoneNumber) {
        return this.$message.error('手机号码不正确')
      }
      if (this.checkPhone.mobile != getPersonInfo().mobile) {
        return this.$message.error('当前手机号和账号绑定的不一致')
      }
      this.isVoice = false
      this.getVerifyCode(data)
    },
    sendVoice(data) {
      if (!this.checkPhone.mobile) return this.$message.error('请输入手机号')
      if (!this.isPhoneNumber) {
        return this.$message.error('手机号码不正确')
      }
      if (this.isVoice) return
      this.isVoice = true
      this.clearSmsTimer()
      this.getVerifyCode(data)
    },
    getVerifyCode(data) {
      if (this.countDownTime) return
      if (!this.checkPhone.mobile) {
        return this.$message.error('请输入手机号')
      }
      if (!this.isPhoneNumber) {
        return this.$message.error('手机号码不正确')
      }
      if (this.isSend) {
        return
      }
      this.isSend = true
      let req = {
        mobile: this.checkPhone.mobile,
        clientType: 30,//1 医生端 2 代表端 30 厂家组织核心
        smsType: 109,//101 注册验证码 102 重置密码 103 更换手机号 104 推流端验证码 105厂家端登录验证码 106厂家端绑定手机获取验证码 107找回密码获取验证码 108修改支付限额 109修改绑定微信
        isVoice: this.isVoice,
      }
      this.getSmsCode(req)
        .then(res => {
          this.$message.success('验证码已发送')
          this.isSend = false
          this.doIntervalCount()
        })
        .catch(rea => {
          this.isSend = false
          if (rea.code === 4000) {
            if (this.isVoice) {
              this.createCaptcha(this.sendVoice)
            } else {
              this.createCaptcha(this.sendSms)
            }
          } else if (rea.code === 4206) {
            this.failDialogVisible = true
            this.passwordErrorModalMsg = '输入的手机号与绑定的手机号不匹配'
          } else if (rea.code === 4016) {
            this.failDialogVisible = true
            this.passwordErrorModalMsg = '手机号未绑定'
          } else {
            this.$message.error(rea.message)
          }
        })
    },
    doIntervalCount() {
      this.countDownTime = 60
      this.smsTimer = setInterval(() => {
        this.countDownTime--
        if (this.countDownTime < 30) {
          this.showVoice = true
        }
        if (this.countDownTime < 1) {
          this.isVoice = false
          this.clearSmsTimer()
        }
      }, 1000)
    },
    clearSmsTimer() {
      this.countDownTime = 0
      this.smsTimer && clearInterval(this.smsTimer)
      this.smsTimer = null
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.checkLogin()
        } else {
          return false
        }
      });
    },
    checkLogin() {
      let req = {
        smsSendType: '109',
        mobile: this.checkPhone.mobile,
        smsCaptcha: this.checkPhone.code
      }
      this.paymentCheckValue(req).then(res => {
        this.checkValue = res
        this.step = 2
        this.initScan()
      }, rea => {
        this.scanDialogVisible = true
        this.scanDialogMsg = rea.message
      })
    },
    createCaptcha(cb, faii) {
      let captcha1 = new TencentCaptcha(this.captchaAppId, (res) => {
        if (res.ret === 0) {
          // 成功 处理回调
          this.onCheckCaptcha(res).then(reason => {
            cb && cb(res)
          })
        } else if (res.ret === 2) {
          // 失败
          faii && faii()
        }
      })
      captcha1.show()
    },
    onCheckCaptcha(data) {
      let req = {
        randStr: data.randstr,
        ticket: data.ticket,
        mobile: this.checkPhone.mobile
      }
      return new Promise((resolve, reject) => {
        this.checkCaptcha(req).then(res => {
          resolve()
        }, rea => {
          reject()
          this.$message.error(rea.message)
        })
      })
    },
    initScan() {//获取扫码二维码
      this.clearScanTimer()
      let req = {
        codeType: 3,
        checkValue: this.checkValue,
      }
      this.getWxCodeImage(req).then(res => {
        this.startScanInterval()
      }, rea => {
        if (rea.code === 10018) {
          this.scanDialogVisible = true
          this.scanDialogMsg = '操作超时，请重新获取验证码'
        } else {
          this.$message.error(rea.message)
        }
      })
    },
    startScanInterval() {//查询扫码结果轮训
      this.scanTimer = setInterval(() => {
        let req = {
          loopKey: this.wxCodeImage.loopKey,
        }
        this.checkWxCodeResult(req).then(res => {
          // 状态：SUCCESS 扫码成功，NO_USER 没找到用户；WAIT_FOR_SCAN 等待扫码; QRCODE_EXPIRED 二维码过期; NO_PERMISSION 不是医生、没认证、没直播等 ; NOT_BIND 未绑定账号；
          if (res.status === 'SUCCESS') {
            this.openId = res.data.openId
            this.wxAvatar = res.data.wxAvatar
            this.wxName = res.data.wxName
            this.clearScanTimer()
            this.step = 3
          } else if (res.status === 'QRCODE_EXPIRED') {
            this.initScan()
          } else if (res.status !== 'WAIT_FOR_SCAN') {
            this.$message.error(res.status)
            this.clearScanTimer()
          }
        }, rea => {
          this.clearScanTimer()
          this.$message.error(rea.message)
        })
      }, 3000)
    },
    clearScanTimer() {
      this.scanTimer && clearInterval(this.scanTimer)
      this.scanTimer = null
    },
    goBack() {
      this.step = 2
      this.initScan()
    },
    submitBindWx() {
      let req = {
        openId: this.openId,
        isChange: true
      }
      this.bindWechat(req).then(res => {
        this.handleSuccess()
        this.step = 4
      }, rea => {
        if (rea.code === 4004) {
          this.failDialogVisible = true
          this.passwordErrorModalMsg = '此微信已绑定其他账号，请更换其他微信扫码，或登录已绑定的账号解绑此手机'
        } else {
          this.$message.error(rea.message)
        }
      })
    },
    handleSuccess() {
      let info = getPersonInfo()
      info.openId = this.openId
      info.wxAvatar = this.wxAvatar
      info.wxName = this.wxName
      setPersonInfo(info)
      this.$store.dispatch('getPermission')
    },
    finish() {
      this.$router.push({ name: 'Index' })
    },
    closeScanErrorModal() {
      this.scanDialogVisible = false
      this.step = 1
    },
    onCloseFailDialogVisible() {
      this.failDialogVisible = false
    },
  },
  beforeCreate() {
    // 引入腾讯云sdk
    initTCaptcha()
  },
  mounted() {
    this.userInfo = getUserInfo()
    this.personInfo = getPersonInfo()
  }
}
</script>
<style lang="scss" scoped>
.container {
  padding: 0;
}

.common-padding {
  position: relative;
  padding: 30px;
  background: white;
  border-bottom: 1px solid #f0f0f0;
}

.safety {
  color: #3D61E3;
}

.back {
  position: absolute;
  right: 70px;
  cursor: pointer;

  .back-img {
    width: 24px;
    height: 18.28px;
    margin-right: 10px;
    vertical-align: middle;
  }
}

.card {
  margin: 20px;
}

.content {
  width: 600px;
  height: 400px;
  margin: 30px auto;
}

.step {
  width: 800px;
}

.steps {
  display: inline-block;
  width: 150px;
  height: 15px;
}

.step-one {
  margin-right: 2px;
  border-radius: 8px 0 0 8px;
  background-color: #3D61E3;
}

.this-step {
  background-color: #3D61E3 !important;
}

.step-two {
  background-color: #EFF0F2;
  margin-right: 2px;
}

.step-three {
  background-color: #EFF0F2;
  margin-right: 2px;
}

.step-four {
  background-color: #EFF0F2;
  border-radius: 0 8px 8px 0;
}

.scan-box {
  padding-top: 35px;
  padding-bottom: 60px;

  .scan-image {
    border: 1px solid #ddd;
    margin-bottom: 10px;

    img {
      width: 200px;
      height: 200px;
    }
  }

  .scan-icon-box {
    margin-top: 28px;
    color: color-assist;
    margin-bottom: 38px;

    .scan-icon {
      width: 30px;
      height: 30px;
      margin-right: 12px;
    }
  }
}

.next-step {
  margin-top: 50px;
  width: 180px;
}

.tip {
  width: 150px;
  padding-top: 10px;
  padding-left: 40px;
  display: inline-block;
}

.one-tip {
  color: #3D61E3;
}

.back-step {
  background-color: #ffffff;
  color: #3D61E3;
}

.remarks {
  font-size: 14px;
  color: #C8C9CE;
}

.sure-bind {
  position: relative;
  margin-bottom: 120px;

  .wx-image {
    img {
      position: absolute;
      width: 70px;
      height: 70px;
      right: 100px;
      border-radius: 50%;
    }
  }

  .fac-image {
    img {
      position: absolute;
      width: 70px;
      height: 70px;
      left: 100px;
      border-radius: 50%;
    }
  }

  .desc-two {
    position: absolute;
    margin-left: 60px;
    top: 90px;
  }

  .factory-name {
    position: absolute;
    left: 70px;
    top: 90px;
  }

  .img-bind {
    width: 30px;
    height: 18px;
    margin: 30px 50px 0 50px;
  }

  .success-img {
    width: 80px;
    height: 80px;
    margin: 40px;
  }

  .font-size-normal {
    padding-top: 20px;
    text-align: center;
  }
}

.mobile {
  width: 500px;
}

.input-width {
  width: 350px;
}

.code {
  width: 180px;
  margin-right: 20px;
}

.get-code {
  width: 180px;
  color: #3D61E3;
  background-color: #ffffff;
}

.voice-code {
  position: absolute;
  right: 0px;
  color: #3D61E3;
  cursor: pointer;
}

.bind {
  margin: 40px 0 0 70px;
  width: 180px;
}

.text-ct {
  padding-top: 20px;
}

.dialog-box >>> .el-dialog__header {
  display: none;
}

.tips {
  padding-bottom: 50px;
}

.change-by-pwd {
  color: #3D61E3;
}
</style>

