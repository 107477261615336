<template>
  <div class="scene-layout">
    <page-main v-loading="loading" title="更多设置">
      <el-row type="flex" class="tab-row flex-wrap">
        <el-button
          :class="{ selected: tab === 'authenticity' }"
          @click="tab = 'authenticity'"
        >
          客户真实性权限设置
        </el-button>
        <el-button
          :class="{ selected: tab === 'rationality' }"
          @click="tab = 'rationality'"
        >
          学术推广合理性设置
        </el-button>
      </el-row>
      <ol v-show="tab === 'authenticity'" class="set-card-wrapper">
        <li class="set-row">
          <span>客户真实性认证等级是：</span>
          <div style="padding-left: 20px;">
            <el-radio
              v-for="item in certifyList"
              :key="item.value"
              v-model="certifyRule.bindDoctorLimit"
              :label="item.value"
              :disabled="!isTopOrg"
            >
              {{ item.label }}
            </el-radio>
            <span class="label">
              备注：未满足绑定条件客户，将会进入代表的预绑定名单，代表可以编辑完善客户信息，协助其提交材料审核。
            </span>
          </div>
        </li>
        <li class="set-row">
          <span>提示客户进行实名验证的位置：</span>
          <div style="padding-left: 20px;">
            <div>
              <el-radio
                v-model="certifyRule.noticeLocation"
                :label="0"
                :disabled="!isTopOrg"
              >
                注册流程中提示客户进行实名验证
              </el-radio>
            </div>
            <div>
              <el-radio
                v-model="certifyRule.noticeLocation"
                :label="1"
                :disabled="!isTopOrg"
              >
                使用积分时提示客户进行实名验证
              </el-radio>
            </div>
          </div>
        </li>
        <li class="set-row">
          <span>工作证明未审核通过的医生，可以领取积分但领取15日内未通过工作证明审核的，对应的积分将会被自动退回到公司对应账号。</span>
        </li>
        <li class="set-row">
          <span>工作证明审核通过但是没有通过实名验证的医生，最多使用</span>
          <el-input-number
            v-model="certifyRule.unCertifyConsumeLimit"
            class="number-step"
            :min="0"
            :disabled="true"
          />
          <span>积分</span>
        </li>
        <li class="set-row">
          <span>实名验证中姓名和工作证明中姓名不匹配的医生，将被严格控制，无法使用任何积分，重新认证全部通过后恢复权限。</span>
        </li>
      </ol>
      <ol v-show="tab === 'rationality'" class="set-card-wrapper">
        <li class="set-row">
          <span>同科会内容活动，同医生重复学习频率限制方案：</span>
          <div style="padding-left: 20px;">
            <div>
              <el-radio
                v-model="certifyRule.meetingMode"
                label="2"
                :disabled="!isTopOrg"
              >
                按自然月度控制，每月的重复活动任务不超过
                <el-input-number
                  v-model="certifyRule.sameMeetingMonthLimit"
                  class="number-step"
                  :min="1"
                  :max="2"
                  step-strictly
                  :disabled="!isTopOrg || certifyRule.meetingMode !== '2'"
                />
                次
              </el-radio>
            </div>
            <div>
              <el-radio
                v-model="certifyRule.meetingMode"
                label="1"
                :disabled="!isTopOrg"
              >
                按自然季度控制，每季度的重复活动任务不超过
                <el-input-number
                  v-model="certifyRule.sameMeetingQuarterLimit"
                  class="number-step"
                  :min="1"
                  :max="6"
                  step-strictly
                  :disabled="!isTopOrg || certifyRule.meetingMode !== '1'"
                />
                次
              </el-radio>
            </div>
            <div>
              <el-radio
                v-model="certifyRule.meetingMode"
                label="3"
                :disabled="!isTopOrg"
              >
                <el-input-number
                  v-model="certifyRule.sameMeetingYearLimitBO.integer1"
                  class="number-step"
                  :min="1"
                  :max="3"
                  step-strictly
                  :disabled="!isTopOrg || certifyRule.meetingMode !== '3'"
                />
                年内重复活动任务不超过
                <el-input-number
                  v-model="certifyRule.sameMeetingYearLimitBO.integer2"
                  class="number-step"
                  :min="1"
                  :max="5"
                  step-strictly
                  :disabled="!isTopOrg || certifyRule.meetingMode !== '3'"
                />
                次
              </el-radio>
            </div>
          </div>
        </li>
        <li class="set-row">
          <span>同问卷调研和病例互动活动，同医生重复学习频率限制方案：</span>
          <div style="padding-left: 20px;">
            <div>
              <el-radio
                v-model="certifyRule.questionnaireMode"
                label="2"
                :disabled="!isTopOrg"
              >
                按自然月度控制，每月的重复活动任务不超过
                <el-input-number
                  v-model="certifyRule.sameQuestionnaireMonthLimit"
                  class="number-step"
                  :min="1"
                  :max="2"
                  step-strictly
                  :disabled="!isTopOrg || certifyRule.questionnaireMode !== '2'"
                />
                次
              </el-radio>
            </div>
            <div>
              <el-radio
                v-model="certifyRule.questionnaireMode"
                label="1"
                :disabled="!isTopOrg"
              >
                按自然季度控制，每季度的重复活动任务不超过
                <el-input-number
                  v-model="certifyRule.sameQuestionnaireQuarterLimit"
                  class="number-step"
                  :min="1"
                  :max="6"
                  step-strictly
                  :disabled="!isTopOrg || certifyRule.questionnaireMode !== '1'"
                />
                次
              </el-radio>
            </div>
            <div>
              <el-radio
                v-model="certifyRule.questionnaireMode"
                label="3"
                :disabled="!isTopOrg"
              >
                <el-input-number
                  v-model="certifyRule.sameQuestionnaireYearLimitBO.integer1"
                  class="number-step"
                  :min="1"
                  :max="3"
                  step-strictly
                  :disabled="!isTopOrg || certifyRule.questionnaireMode !== '3'"
                />
                年内重复活动任务不超过
                <el-input-number
                  v-model="certifyRule.sameQuestionnaireYearLimitBO.integer2"
                  class="number-step"
                  :min="1"
                  :max="5"
                  step-strictly
                  :disabled="!isTopOrg || certifyRule.questionnaireMode !== '3'"
                />
                次
              </el-radio>
            </div>
          </div>
        </li>

        <li class="set-row">
          <span>直播会议观看时长默认值：</span>
          <el-input-number
            v-model="certifyRule.liveDefaultReviewSec"
            :min="liveDefaultReviewSecMin"
            :max="liveAndPodcastDefaultReviewSecMax"
          />
          分钟
        </li>
        <li class="set-row">
          <span>播客活动内容征集时长最低限制：</span>
          <el-input-number
            v-model="certifyRule.podcastVideoTimeLimit"
            :min="Math.max(podcastDefaultReviewSecMin,certifyRule.liveDefaultReviewSec)"
            :max="liveAndPodcastDefaultReviewSecMax"
            :step="1"
            step-strictly
          />
          分钟
          <el-tooltip
            effect="light"
            popper-class="customize-el-tooltip"
            content="最低限制不可低于直播会议观看时长默认值"
            placement="right"
          >
            <svg-icon name="el-icon-question" style="color: #dce0e6;" />
          </el-tooltip>
        </li>
      </ol>
      <FixedActionBar v-show="isTopOrg">
        <el-button
          style="min-width: 90px;"
          plain
          type="primary"
          @click="goBack"
        >
          取消
        </el-button>
        <el-button
          style="min-width: 90px;"
          type="primary"
          @click="onSetOpenSubmit"
        >
          保存
        </el-button>
      </FixedActionBar>
    </page-main>
    <OperationConfirmPop
      :visible="isShowOperationConfirmPop"
      message="当前页面未操作完成，您确定要放弃当前操作吗？"
      title="操作确认"
      cancel-font="继续编辑"
      confirm-font="确定离开"
      @onClose="isShowOperationConfirmPop = false"
      @onSubmit="goBack"
    />
    <el-dialog
      width="500px"
      title="确认修改"
      :visible="showSubmit"
      class="customize-el-dialog"
      top="30vh"
      @close="showSubmit = false"
    >
      <div
        style="margin: 20px 0;"
        class="font-weight align-center font-size-root"
      >
        确认保存修改吗？
      </div>
      <el-row
        slot="footer"
        type="flex"
        align="center"
        justify="center"
      >
        <el-button @click="showSubmit = false">取消</el-button>
        <el-button
          type="primary"
          :loading="isSubmitLoading"
          @click="onSaveData"
        >
          确认
        </el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { merge } from 'lodash-es'
import OperationConfirmPop from '@/bizComponents/OperationConfirmPop'

export default {
  name: 'DoctorCertifySetRule',
  components: {
    OperationConfirmPop
  },
  data() {
    return {
      tab: 'authenticity',
      showSubmit: false,
      isShowOperationConfirmPop: false,
      isSubmitLoading: false,
      loading: false,
      liveDefaultReviewSecMin: 10,
      liveAndPodcastDefaultReviewSecMax: 39,
      // TODO: 长卿测试用
      podcastDefaultReviewSecMin: 15,
      certifyRule: {
        noticeLocation: 0,
        bindDoctorLimit: 1,
        liveDefaultReviewSec: 10,
        podcastVideoTimeLimit: 15,
        sameMeetingMonthLimit: 2,
        sameMeetingQuarterLimit: 6,
        sameMeetingYearLimitBO: {
          integer1: 3,
          integer2: 5
        },
        sameQuestionnaireMonthLimit: 2,
        sameQuestionnaireQuarterLimit: 6,
        sameQuestionnaireYearLimitBO: {
          integer1: 3,
          integer2: 5
        }
      },

      certifyList: [
        {
          value: 1,
          label: '等级Ⅰ 执业医师认证成功即可绑定（需上传该医生的执业证书照片以及职称证明材料）'
        },
        {
          value: 2,
          label: '等级Ⅱ 执业医师认证+人脸活体认证，身份认证成功即可绑定'
        },
        {
          value: 3,
          label:
            '等级Ⅲ 执业医师认证+人脸活体认证+手机号实名检测，三重认证都成功后，即可绑定'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['factoryId', 'isTopOrg'])
  },
  mounted() {
    this.initPage()
  },
  methods: {
    ...mapActions([
      'getOrgList',
      'setDoctorCertifyRule',
      'getDoctorCertifyRule'
    ]),
    async initPage() {
      this.loading = true
      try {
        !this.factoryId && (await this.getOrgList())
        const data = await this.getDoctorCertifyRule({ uOrgId: this.factoryId })
        data.meetingMode = data.sameMeetingQuarterLimit
          ? '1'
          : data.sameMeetingMonthLimit
            ? '2'
            : data.sameMeetingYearLimitBO
              ? '3'
              : null
        data.questionnaireMode = data.sameQuestionnaireQuarterLimit
          ? '1'
          : data.sameQuestionnaireMonthLimit
            ? '2'
            : data.sameQuestionnaireYearLimitBO
              ? '3'
              : null
        data.liveDefaultReviewSec = data.liveDefaultReviewSec / 60
        data.podcastVideoTimeLimit = data.podcastVideoTimeLimit / 60

        data.sameMeetingYearLimitBO = {
          integer1: data.sameMeetingYearLimitBO?.integer1 || 1,
          integer2: data.sameMeetingYearLimitBO?.integer2 || 1
        }
        data.sameQuestionnaireYearLimitBO = {
          integer1: data.sameQuestionnaireYearLimitBO?.integer1 || 1,
          integer2: data.sameQuestionnaireYearLimitBO?.integer2 || 1
        }
        this.certifyRule = merge({}, this.certifyRule, data)
        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    onSetOpenSubmit() {
      if (!this.onValid()) return
      this.showSubmit = true
    },
    onValid() {
      if (
        !this.certifyRule.unCertifyConsumeLimit &&
        this.certifyRule.unCertifyConsumeLimit != 0
      ) {
        this.$message.error('请输入使用积分上限')
        return false
      }
      if (!(this.certifyRule.podcastVideoTimeLimit >= Math.max(this.podcastDefaultReviewSecMin, this.certifyRule.liveDefaultReviewSec) && this.certifyRule.podcastVideoTimeLimit <= this.liveAndPodcastDefaultReviewSecMax)) {
        this.$message.error(`播客活动内容征集时长最低限制应大于${Math.max(this.podcastDefaultReviewSecMin, this.certifyRule.liveDefaultReviewSec)}分钟`)
        return false
      }
      return true
    },
    onSaveData() {
      if (this.isSubmitLoading) return
      this.isSubmitLoading = true

      const params = {
        ...this.certifyRule,
        liveDefaultReviewSec: this.certifyRule.liveDefaultReviewSec * 60,
        podcastVideoTimeLimit: this.certifyRule.podcastVideoTimeLimit * 60
      }
      if (params.meetingMode) {
        if (params.meetingMode === '1') {
          delete params.sameMeetingMonthLimit
          delete params.sameMeetingYearLimit
          delete params.sameMeetingYearLimitBO
        }
        if (params.meetingMode === '2') {
          delete params.sameMeetingQuarterLimit
          delete params.sameMeetingYearLimit
          delete params.sameMeetingYearLimitBO
        }
        if (params.meetingMode === '3') {
          delete params.sameMeetingMonthLimit
          delete params.sameMeetingQuarterLimit
        }
      }
      if (params.questionnaireMode) {
        if (params.questionnaireMode === '1') {
          delete params.sameQuestionnaireMonthLimit
          delete params.sameQuestionnaireYearLimit
          delete params.sameQuestionnaireYearLimitBO
        }
        if (params.questionnaireMode === '2') {
          delete params.sameQuestionnaireQuarterLimit
          delete params.sameQuestionnaireYearLimit
          delete params.sameQuestionnaireYearLimitBO
        }
        if (params.questionnaireMode === '3') {
          delete params.sameQuestionnaireMonthLimit
          delete params.sameQuestionnaireQuarterLimit
        }
      }
      this.setDoctorCertifyRule(params).then(
        () => {
          this.isSubmitLoading = false
          this.showSubmit = false
          this.$message.success('认证规则设置成功')
        },
        rea => {
          this.isSubmitLoading = false
          this.$message.error(rea)
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.scene-layout ::v-deep {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .el-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-card__header {
      padding-left: 25px;
      font-size: 16px;
      font-weight: bold;
    }
    .el-card__body {
      flex: 1;
      display: flex;
      padding: 0;
      flex-direction: column;
    }
  }
  .el-radio {
    width: 100%;
    text-overflow: ellipsis;
    white-space: normal;
  }
}
.set-card-wrapper {
  color: #0f0f0f;
  line-height: 3;
  font-size: 16px;
  padding: 20px 40px;
  font-weight: bold;
  flex: 1;
  .label {
    color: #999;
    font-size: 14px;
    font-weight: normal;
  }
}
.number-step {
  margin-left: 12px;
  margin-right: 12px;
}
.tab-row {
  margin-top: 20px;
  margin-left: 20px;
  .el-button {
    background: #fafafa;
    color: #5a5a5a;
    border: none;
  }
  .el-button.selected {
    background: rgba(61, 97, 227, 0.12);
    color: #3d61e3;
  }
}
.num-input {
  width: 90px;
  display: inline-block;
}
.num-input ::v-deep .el-input__inner {
  height: 30px;
  line-height: 30px;
}
</style>
