<template>
  <div class="container">
    <div class="common-padding flex">
      <div class="font-size-large font-weight"><span class="safety">账号安全</span>-修改支付限额</div>
      <div class="back" @click="onBack">
        <img class="back-img" src="../../assets/user/icon_back.png" alt="">返回上一级
      </div>
    </div>
    <el-card class="card">
      <div class="content">
        <div class="step">
          <div class="steps step-one"></div>
          <div class="steps step-two" :class="{'this-step': step != 1}"></div>
          <div class="steps step-three" :class="{'this-step': step === 3}"></div>
        </div>
        <div class="tips">
          <div class="tip one-tip">1.验证手机号</div>
          <div class="tip" :class="{'one-tip': step != 1}">2.修改支付限额</div>
          <div class="tip" :class="{'one-tip': step === 3}">3.完成修改</div>
        </div>
        <div class="scan-box flex flex-column vertical-center" v-if="step === 1">
          <el-form label-width="120px" :model="paymentSetting" :rules="paymentSettingRules" ref="paymentSetting">
            <el-form-item label="已绑定的手机号" class="mobile" prop="mobile">
              <el-input v-model="paymentSetting.mobile" maxlength='11' minlength='11'></el-input>
            </el-form-item>
            <div class="inline" :inline="true">
              <el-form-item label="验证码" prop="code">
                <el-input v-model="paymentSetting.code" class="code"></el-input>
                <el-button type="primary" v-if='countDownTime == 0' @click="sendSms" class="get-code"
                           :class="{'disable': !isPhoneNumber && isSend }">{{ isSend ? '发送中' : '获取验证码' }}
                </el-button>
                <el-button type="primary" class="get-code" v-else>{{ countDownTime }}s重新获取</el-button>
                <div class="voice-code" @click='sendVoice' v-show='showVoice'>收不到验证码？试试语音验证</div>
              </el-form-item>
            </div>
            <el-form-item>
              <el-button type="primary" @click="submitForm('paymentSetting')" class="bind">验证手机号</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="scan-box flex flex-column vertical-center" v-if="step === 2">
          <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="payRuleForm">
            <el-form-item label="" prop="newPass" class="newPass">支付超过
              <el-input type="text" v-model="ruleForm.pay" autocomplete="off" class="input-width"></el-input>
              积分时，需要微信扫码验证
            </el-form-item>
            <div class="setting">备注：支付限额最高不超过10000积分。支付身份验证在涉及账号的敏感操作和异常时，可通过短信或微信通知到您的手机，可以提高账号的安全指数。</div>
            <el-form-item>
              <el-button type="primary" class="next-step" @click="handleSmsSubmit">确认</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="scan flex flex-column vertical-center" v-if="step === 3">
          <img src="../../assets/user/icon_success.png" alt="" class="success-img">
          <div class="font-size-normal">支付限额修改完成！</div>
          <div class="flex level-center vertical-center scan-icon-box">
            <div class="flex vertical-center">
              <el-button type="primary" class="finish" @click="finish">完成</el-button>
            </div>
          </div>
        </div>
        <el-dialog
          width="25%"
          :visible.sync="failDialogVisible"
          :center='true'
          :show-close='false'
          top='30vh'
          class='dialog-box'
        >
          <div class="flex level-center">
            <img src="@/assets/common/icon_failed.png" alt="">
          </div>
          <div class="text-ct margin-t25">{{ passwordErrorModalMsg }}</div>
          <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onCloseFailDialogVisible">我知道了</el-button>
      </span>
        </el-dialog>
        <el-dialog
          width="25%"
          :visible.sync="failTimeDialogVisible"
          :center='true'
          :show-close='false'
          top='30vh'
          class='dialog-box'
        >
          <div class="flex level-center">
            <img src="@/assets/common/icon_failed.png" alt="">
          </div>
          <div class="text-ct margin-t25">{{ timeErrorModalMsg }}</div>
          <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onCloseFailTimeDialogVisible">我知道了</el-button>
      </span>
        </el-dialog>
      </div>
    </el-card>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { initTCaptcha } from "@/utils/util";
import { getPersonInfo } from "@/utils/auth";

export default {
  name: "paymentSetting",
  data() {
    return {
      step: 1,
      paymentSetting: {
        mobile: '',
        code: ''
      },
      paymentSettingRules: {
        mobile: [
          { required: true, message: '请填写手机号', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请填写验证码', trigger: 'blur' }
        ],
      },
      failDialogVisible: false,
      failTimeDialogVisible: false,
      isSend: false,
      isVoice: false,
      countDownTime: 0,
      showVoice: false,
      smsTimer: null,
      checkValue: '',
      passwordErrorModalMsg: '',
      timeErrorModalMsg: '',
      ruleForm: {
        pay: ''
      }
    }
  },
  computed: {
    ...mapGetters(['captchaAppId']),
    isPhoneNumber() {
      return /^\d{11}$/gi.test(this.paymentSetting.mobile)
    },
    isPositiveInteger() {
      return /^[0-9]\d*$/.test(this.ruleForm.pay)
    },
  },
  components: {
  },
  methods: {
    ...mapActions(['paymentLimit', 'paymentCheckValue', 'getSmsCode','checkCaptcha']),
    onBack() {
      this.$router.go(-1)
    },
    finish() {
      this.$router.push({name:'SafeCenter'})
    },
    onCloseFailDialogVisible() {
      this.failDialogVisible = false
    },
    onCloseFailTimeDialogVisible() {
      this.failTimeDialogVisible = false
      this.step = 1
      this.paymentSetting = {
        mobile: '',
        code: ''
      }
    },
    sendSms(data) {
      if (!this.paymentSetting.mobile)
        return
      if (!this.isPhoneNumber) {
        return this.$message.error('手机号码不正确')
      }
      if (this.paymentSetting.mobile != getPersonInfo().mobile) {
        return this.$message.error('当前手机号和账号绑定的不一致')
      }
      this.isVoice = false
      this.getVerifyCode(data)
    },
    sendVoice(data) {
      if (!this.paymentSetting.mobile) return this.$message.error('请输入手机号')
      if (!this.isPhoneNumber) {
        return this.$message.error('手机号码不正确')
      }
      if (this.isVoice) return
      this.isVoice = true
      this.clearSmsTimer()
      this.getVerifyCode(data)
    },
    getVerifyCode(data) {
      if (this.countDownTime) return
      if (!this.paymentSetting.mobile) {
        return this.$message.error('请输入手机号')
      }
      if (!this.isPhoneNumber) {
        return this.$message.error('手机号码不正确')
      }
      if (this.isSend) {
        return
      }
      this.isSend = true
      let req = {
        mobile: this.paymentSetting.mobile,
        clientType: 30,//1 医生端 2 代表端 30 厂家组织核心
        smsType: 108,//101 注册验证码 102 重置密码 103 更换手机号 104 推流端验证码 105厂家端登录验证码 106厂家端绑定手机获取验证码 107找回密码获取验证码 108修改支付限额 109修改绑定微信
        isVoice: this.isVoice,

      }
      this.getSmsCode(req)
        .then(res => {
          this.$message.success('验证码已发送')
          this.isSend = false
          this.doIntervalCount()
        })
        .catch(rea => {
          this.isSend = false
          if (rea.code === 4000){
            if (this.isVoice){
              this.createCaptcha(this.sendVoice)
            }else {
              this.createCaptcha(this.sendSms)
            }
          }else {
            this.$message.error(rea.message)
          }
        })
    },
    doIntervalCount() {
      this.countDownTime = 60
      this.smsTimer = setInterval(() => {
        this.countDownTime--
        if (this.countDownTime < 30) {
          this.showVoice = true
        }
        if (this.countDownTime < 1) {
          this.isVoice = false
          this.clearSmsTimer()
        }
      }, 1000)
    },
    clearSmsTimer() {
      this.countDownTime = 0
      this.smsTimer && clearInterval(this.smsTimer)
      this.smsTimer = null
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.checkLogin()
        } else {
          return false
        }
      });
    },
    checkLogin() {
      let req = {
        smsSendType: '108',
        mobile: this.paymentSetting.mobile,
        smsCaptcha: this.paymentSetting.code
      }
      this.paymentCheckValue(req).then(res => {
        this.checkValue = res
        this.step = 2
      }, rea => {
        this.failDialogVisible = true
        this.passwordErrorModalMsg = rea.message
      })
    },
    handleSmsSubmit() {
      if (!this.ruleForm.pay) {
        return this.$message.error('请填写支付限额')
      }
      if (this.ruleForm.pay > 10000) {
        return this.$message.error('支付限额最高不可超过10000积分')
      }
      if (!this.isPositiveInteger) {
        return this.$message.error('支付限额必须正整数')
      }
      let req = {
        payLimit: this.ruleForm.pay,
        checkValue: this.checkValue,
      }
      this.paymentLimit(req).then(res => {
        this.step = 3
      }, rea => {
        if (rea.code === 10018){
          this.failTimeDialogVisible = true
          this.timeErrorModalMsg = '操作超时，请重新获取验证码'
        }else {
          this.$message.error(rea.message)
        }
      })
    },
    // 创建腾讯云验证 传入回调
    createCaptcha(cb,faii){
      let captcha1 = new TencentCaptcha(this.captchaAppId, (res)=>{
        if(res.ret === 0){
          // 成功 处理回调
          this.onCheckCaptcha(res).then(reason=>{
            cb && cb(res)
          })
        }else if(res.ret === 2){
          // 失败
          faii && faii()
        }
      })
      captcha1.show()
    },
    onCheckCaptcha(data){
      let req = {
        randStr:data.randstr,
        ticket:data.ticket,
        mobile:this.paymentSetting.mobile
      }
      return new Promise((resolve,reject)=>{
        this.checkCaptcha(req).then(res=>{
          resolve()
        },rea=>{
          reject()
          this.$message.error(rea.message)
        })
      })
    }
  },
  beforeCreate() {
    // 引入腾讯云sdk
    initTCaptcha()
  },
  mounted() {
  }
}

</script>
<style lang="scss" scoped>
.container {
  padding: 0;
}

.common-padding {
  position: relative;
  padding: 30px;
  background: white;
  border-bottom: 1px solid #f0f0f0;
}

.safety {
  color: #3D61E3;
}

.back {
  position: absolute;
  right: 70px;
  cursor: pointer;

  .back-img {
    width: 24px;
    height: 18.28px;
    margin-right: 10px;
    vertical-align: middle;
  }
}

.card {
  margin: 20px;
}

.content {
  width: 600px;
  height: 400px;
  margin: 30px auto;
}

.step {
  width: 700px;
}

.steps {
  display: inline-block;
  width: 200px;
  height: 15px;
}

.step-one {
  margin-right: 2px;
  border-radius: 8px 0 0 8px;
  background-color: #3D61E3;
}

.this-step {
  background-color: #3D61E3 !important;
}

.step-two {
  background-color: #EFF0F2;
  margin-right: 2px;
}

.step-three {
  background-color: #EFF0F2;
  border-radius: 0 8px 8px 0;
}

.scan-box {
  padding-top: 30px;
  padding-bottom: 60px;
}

.payRuleForm {
  margin-left: 30px;
}

.newPass {
  padding-bottom: 20px;
}

.scan-icon-box {
  margin-top: 28px;
  margin-bottom: 38px;

  .scan-icon {
    width: 30px;
    height: 30px;
    margin-right: 12px;
  }
}

.next-step {
  margin: 50px 0 0 90px;
  width: 180px;
}

.tip {
  width: 200px;
  padding-top: 10px;
  padding-left: 60px;
  display: inline-block;
}

.one-tip {
  color: #3D61E3;
}

.back-step {
  background-color: #ffffff;
  color: #3D61E3;
}

.success-img {
  width: 80px;
  height: 80px;
  margin: 40px;
}

.font-size-normal {
  text-align: center;
}

.mobile {
  width: 500px;
}

.input-width {
  width: 100px;
}

.code {
  width: 180px;
  margin-right: 20px;
}

.get-code {
  width: 180px;
  color: #3D61E3;
  background-color: #ffffff;
}

.voice-code {
  position: absolute;
  right: 0;
  color: #3D61E3;
  cursor: pointer;
}

.bind {
  margin: 40px 0 0 70px;
  width: 180px;
}

.text-ct {
  padding-top: 20px;
}

.dialog-box >>> .el-dialog__header {
  display: none;
}

.tips {
  padding-bottom: 50px;
}

.setting {
  color: #C8C9CE;
}

.finish {
  margin-top: 30px;
  width: 180px;
}
</style>

