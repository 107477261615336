<template>
  <div>
    <page-main title back>
      <el-row v-if="isMobile">
        <div class="number-box">
          <ScoreCard :score="score" :time="time" />
        </div>

        <div class="info-box flex flex-column level-between">
          <div class="font-16 info-title">
            基础信息
            <el-popover
              placement="bottom"
              title="温馨提示"
              width="270"
              trigger="hover"
              content="同一手机号下关联多个账号的，可以点击个人中心个人中心，在下拉选项中切换账号。"
            >
              <i slot="reference" class="el-icon-question" />
            </el-popover>
          </div>
          <div class="flex-start mgn-b10">
            <div>组织logo：</div>
            <el-upload
              class="avatar-uploader"
              :action="uploadApi"
              :headers="headers"
              name="file"
              :show-file-list="false"
              accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :disabled="!hasLimitPermission"
            >
              <div class="flex vertical-start">
                <img
                  v-if="imageUrl"
                  :src="imageUrl"
                  class="avatar"
                  alt=""
                >
                <img
                  v-else
                  class="avatar"
                  :src="require('@/assets/user/icon_organize.png')"
                  alt=""
                >
                <div v-if="hasLimitPermission">
                  <div class="color-primary pointer">点击上传 ></div>
                  <div class="tips"> 建议尺寸100*100</div>
                </div>
              </div>
            </el-upload>
          </div>
          <div class="mgn-b10">账<span class="mgn-r23" /> 号：{{ accountCenterInfo.accountInfoVO.login }}</div>
          <div class="mgn-b10">姓<span class="mgn-r23" /> 名：{{ accountCenterInfo.accountInfoVO.name }}</div>
          <div class="mgn-b10">
            部<span class="mgn-r23" /> 门：{{ accountCenterInfo.accountInfoVO.factoryName }}
          </div>
        </div>

        <el-row class="mgn-lr30">
          <el-row class="flex vertical-center">
            <el-col :span="4">
              <img src="@/assets/user/icon_lock.png" alt="">
            </el-col>
            <el-col :span="20">
              <div class="flex vertical-center info-margin">
                <div class="flex-shrink">账号密码：</div>
                <div v-if="hasPassword" class="password-text" :class="passwordClass">
                  {{ accountCenterInfo.accountInfoVO.pwdSafetyDegree | passwordTextFilter }}
                </div>
                <div v-else>未设置</div>
              </div>
            </el-col>
          </el-row>

          <el-row class="flex vertical-center">
            <el-col :span="4">
              <img src="@/assets/user/icon_mobile.png" alt="">
            </el-col>
            <el-col :span="20">
              <div class="flex vertical-center info-margin">
                <div class="flex-shrink">绑定手机号：</div>
                <div v-if="accountCenterInfo.bindMobile" class="password-text">
                  {{ accountCenterInfo.bindMobile }}
                </div>
                <div v-else>未绑定</div>
              </div>
              <div class="flex vertical-center" @click="onJumpMobile">
                <div v-if="!accountCenterInfo.bindMobile" class="unset-circle" />
              </div>
            </el-col>
          </el-row>
          <el-row class="flex vertical-center">
            <el-col :span="4">
              <img src="@/assets/user/icon_wechat.png" alt="">
            </el-col>
            <el-col :span="20">
              <div class="flex vertical-center info-margin">
                <div class="flex-shrink">绑定微信：</div>
                <div v-if="accountCenterInfo.isBindWx" class="password-text">
                  {{ accountCenterInfo.bindWeChatName }}
                </div>
                <div v-else>未绑定</div>
              </div>
              <div class="flex vertical-center" @click="onJumpWechat" />
            </el-col>
          </el-row>
        </el-row>

        <el-row class="mgn-lr30 mgn-tb30">
          <el-row class="flex vertical-center">
            <el-col :span="4">
              <img src="@/assets/user/icon_payment.png" alt="">
            </el-col>
            <el-col :span="20">
              <div class="flex vertical-center info-margin">
                <div class="flex-shrink">大额支付验证：</div>
                <div class="password-text">{{ accountCenterInfo.payLimit }}</div>
              </div>
            </el-col>
          </el-row>
          <div class="info-assist">
            1.为了提升账号安全等级，系统会默认开启大额支付二次验证。<br>
            2.为了保证资金操作为您本人，请尽快绑定微信号。<br>
            3.在涉及资金等敏感操作时，系统会自动发送通知到您的手机。
          </div>
        </el-row>
      </el-row>
      <el-row v-else :gutter="20" class="top-content">
        <el-col :span="6">
          <div class="number-box">
            <ScoreCard :score="score" :time="time" />
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-box flex flex-column level-between">
            <div>
              <div class="font-16 info-title">
                基础信息
                <el-popover
                  placement="bottom"
                  title="温馨提示"
                  width="270"
                  trigger="hover"
                  content="同一手机号下关联多个账号的，可以点击个人中心个人中心，在下拉选项中切换账号。"
                >
                  <i slot="reference" class="el-icon-question" />
                </el-popover>
              </div>
              <div class="flex-start mgn-b10">
                <div>组织logo：</div>
                <el-upload
                  class="avatar-uploader"
                  :action="uploadApi"
                  :headers="headers"
                  name="file"
                  :show-file-list="false"
                  accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :disabled="!hasLimitPermission"
                >
                  <div class="flex vertical-start">
                    <img
                      v-if="imageUrl"
                      :src="imageUrl"
                      class="avatar"
                      alt=""
                    >
                    <img
                      v-else
                      class="avatar"
                      :src="require('@/assets/user/icon_organize.png')"
                      alt=""
                    >
                    <div v-if="hasLimitPermission">
                      <div class="color-primary pointer">点击上传 ></div>
                      <div class="tips"> 建议尺寸100*100</div>
                    </div>
                  </div>
                </el-upload>
              </div>
              <div class="mgn-b10">账<span class="mgn-r23" /> 号：{{ accountCenterInfo.accountInfoVO.login }}</div>
              <div class="mgn-b10">姓<span class="mgn-r23" /> 名：{{ accountCenterInfo.accountInfoVO.name }}</div>
              <div class="mgn-b10">
                部<span class="mgn-r23" /> 门：{{ accountCenterInfo.accountInfoVO.factoryName }}
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <el-row class="bind-box">
            <el-col :span="12" class="bind-box-left flex flex-column level-between">
              <el-row class="flex vertical-center">
                <el-col :span="4">
                  <img src="@/assets/user/icon_lock.png" alt="">
                </el-col>
                <el-col :span="20">
                  <div class="flex vertical-center info-margin">
                    <div class="flex-shrink">账号密码：</div>
                    <div v-if="hasPassword" class="password-text" :class="passwordClass">
                      {{ accountCenterInfo.accountInfoVO.pwdSafetyDegree | passwordTextFilter }}
                    </div>
                    <div v-else>未设置</div>
                  </div>
                  <div class="flex vertical-center" @click="onJumpPassword">
                    <div class="color-primary flex-shrink pointer">{{ hasPassword ? '修改' : '设置' }}密码 ></div>
                    <div v-if="hasPassword" class="flex-box flex level-between">
                      <div
                        v-for="item in passwordItem"
                        :key="item"
                        class="flex-box password-item"
                        :class="passwordItemClass(item)"
                      />
                    </div>
                    <!-- <div v-else class="unset-circle"></div> -->
                  </div>
                </el-col>
              </el-row>
              <el-row class="flex vertical-center">
                <el-col :span="4">
                  <img src="@/assets/user/icon_mobile.png" alt="">
                </el-col>
                <el-col :span="20">
                  <div class="flex vertical-center info-margin">
                    <div class="flex-shrink">绑定手机号：</div>
                    <div v-if="accountCenterInfo.bindMobile" class="password-text">
                      {{ accountCenterInfo.bindMobile }}
                    </div>
                    <div v-else>未绑定</div>
                  </div>
                  <div class="flex vertical-center" @click="onJumpMobile">
                    <div class="color-primary pointer">{{ accountCenterInfo.bindMobile ? '更换' : '绑定' }}手机号 ></div>
                    <div v-if="!accountCenterInfo.bindMobile" class="unset-circle" />
                  </div>
                </el-col>
              </el-row>
              <el-row class="flex vertical-center">
                <el-col :span="4">
                  <img src="@/assets/user/icon_wechat.png" alt="">
                </el-col>
                <el-col :span="20">
                  <div class="flex vertical-center info-margin">
                    <div class="flex-shrink">绑定微信：</div>
                    <div v-if="accountCenterInfo.isBindWx" class="password-text">
                      {{ accountCenterInfo.bindWeChatName }}
                    </div>
                    <div v-else>未绑定</div>
                  </div>
                  <div class="flex vertical-center" @click="onJumpWechat">
                    <div class="color-primary pointer">{{ accountCenterInfo.isBindWx ? '更换' : '绑定' }}微信号 ></div>
                    <div v-if="!accountCenterInfo.isBindWx" class="unset-circle" />
                  </div>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="12" class="bind-box-right flex flex-column level-between">
              <el-row class="flex vertical-center">
                <el-col :span="4">
                  <img src="@/assets/user/icon_payment.png" alt="">
                </el-col>
                <el-col :span="20">
                  <div class="flex vertical-center info-margin">
                    <div class="flex-shrink">大额支付验证：</div>
                    <div class="password-text">{{ accountCenterInfo.payLimit }}</div>
                  </div>
                  <div v-if="hasLimitPermission" class="flex vertical-center" @click="onJumpPayment">
                    <div class="color-primary pointer">修改限额 ></div>
                  </div>
                </el-col>
              </el-row>
              <div class="info-assist">
                1.为了提升账号安全等级，系统会默认开启大额支付二次验证。<br>
                2.为了保证资金操作为您本人，请尽快绑定微信号。<br>
                3.在涉及资金等敏感操作时，系统会自动发送通知到您的手机。
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-card :body-style="cardBodyStyle" shadow="never">
        <el-tabs v-model="activeTab">
          <el-tab-pane
            v-for="item in tabList"
            :key="item.key"
            :label="item.name"
            :name="item.key"
          />
          <div class="lay-box">
            <LoginRecordChild v-if="activeTab === 'login'" />
            <SensitiveRecordChild v-if="activeTab === 'sensitive'" />
          </div>
        </el-tabs>
      </el-card>
    </page-main>
    <!-- 账号登录失败弹窗提醒 -->
    <el-dialog
      :visible.sync="mobileDialogVisible"
      width="30%"
      :center="true"
      :show-close="false"
      top="30vh"
      class="dialog-box"
    >
      <div class="flex level-center">
        <img src="@/assets/common/icon_failed.png" alt="">
      </div>
      <div class="text-ct margin-t25">当前账号还未绑定手机号，请先绑定手机号</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeMobileModal">去绑定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import LoginRecordChild from './components/LoginRecordChild'
import SensitiveRecordChild from './components/SensitiveRecordChild'
import ScoreCard from './components/ScoreCard'
import { getToken, getUserInfo } from '@/utils/auth'
import API from '@/apis/api-types'
import { isMobile } from '@/utils/util.js'
export default {
  name: 'AccountCenter',
  components: {
    LoginRecordChild,
    SensitiveRecordChild,
    ScoreCard
  },
  filters: {
    passwordTextFilter(val) {
      const obj = {
        1: '密码安全度低，建议立即修改',
        2: '安全度中等，建议修改',
        3: '已设置'
      }
      return obj[val] || ''
    }
  },
  data() {
    return {
      headers: {
        token: getToken()
      },
      tabList: [{ name: '登录记录', key: 'login' }, { name: '敏感操作', key: 'sensitive' }],
      activeTab: 'login', // login sensitive
      cardBodyStyle: { paddingTop: 0 },
      score: 0,
      time: '',
      passwordItem: 3, // 密码强度item个数
      mobileDialogVisible: false,
      imageUrl: '',
      isMobile: isMobile()
    }
  },
  computed: {
    ...mapGetters(['accountCenterInfo']),
    passwordClass() {
      const obj = {
        1: 'red',
        2: 'yellow'
      }
      return obj[this.accountCenterInfo.accountInfoVO.pwdSafetyDegree] || ''
    },
    hasPassword() {
      return this.accountCenterInfo.accountInfoVO.pwdSafetyDegree && this.accountCenterInfo.accountInfoVO.pwdSafetyDegree != -1
    },
    hasLimitPermission() {
      return getUserInfo().userType == 1
    },
    uploadApi() {
      return `${API.uploadLogo}`
    }
  },
  mounted() {
    this.initInfo()
    this.initScore()
    this.time = this.$moment().format('YYYY-MM-DD')
  },
  methods: {
    ...mapActions(['getAccountInfo', 'getAccountScore']),
    initInfo() {
      this.getAccountInfo().then(res => {
        this.imageUrl = res.logoUrl || ''
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    initScore() {
      this.getAccountScore().then(res => {
        this.score = res
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload(file) {
      const _this = this
      const isSize = new Promise(function(resolve, reject) {
        const width = 100 // 限制图片尺寸为100*100
        const height = 100
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = function() {
          const valid = img.width === width && img.height === height
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return file
      }, () => {
        _this.$message.warning('建议封面图片尺寸100*100')
        return Promise.resolve()
      })
      return isSize
    },
    onJumpPassword() {
      if (!this.accountCenterInfo.bindMobile) {
        this.mobileDialogVisible = true
        return
      }
      if (this.hasPassword) {
        // 添加修改密码路径
        this.$router.push({ name: 'ChangePwd' })
      } else {
        // 添加设置密码路径
        this.$router.push({ name: 'ChangePassword' })
      }
    },
    onJumpMobile() {
      if (this.accountCenterInfo.bindMobile) {
        // 添加修改手机
        this.$router.push({ name: 'ChangeMobile' })
      } else {
        this.$router.push({ name: 'BindMobile' })
      }
    },
    onJumpWechat() {
      if (!this.accountCenterInfo.bindMobile) {
        this.mobileDialogVisible = true
        return
      }
      if (this.accountCenterInfo.bindWeChatName) {
        // 添加修改微信路径
        this.$router.push({ name: 'ChangeWechat' })
      } else {
        this.$router.push({ name: 'BindWechat' })
      }
    },
    onJumpPayment() {
      if (!this.accountCenterInfo.bindMobile) {
        this.mobileDialogVisible = true
        return
      }
      this.$router.push({ name: 'PaymentSetting' })
    },
    passwordItemClass(index) {
      const obj = {
        1: { color: 'red', position: 1 },
        2: { color: 'yellow', position: 2 },
        3: { color: 'green', position: 3 }
      }
      const newObj = obj[this.accountCenterInfo.accountInfoVO.pwdSafetyDegree] || 0
      if (index <= newObj.position) {
        return newObj.color
      } else {
        return ''
      }
    },
    closeMobileModal() {
      this.mobileDialogVisible = false
      this.$router.push({ name: 'BindMobile' })
    }
  }
}
</script>
<style lang="scss" scoped>
.top-content {
  margin-bottom: 20px;
}
.number-box,
.bind-box,
.info-box {
  background-color: #fff;
  height: 260px;
}
.info-box {
  padding: 30px;
}
.bind-box {
  padding: 30px 40px;
  min-width: 680px;
}
.font-16 {
  font-size: 16px;
}
.info-title {
  margin-bottom: 22px;
}
.info-assist {
  color: #c8c9ce;
}
.bind-box-left {
  border-right: 1px solid #e9eaeb;
  padding-right: 40px;
  height: 100%;
}
.bind-box-right {
  padding-left: 40px;
  height: 100%;
}
.password-text {
  &.red {
    color: #f6625e;
  }
  &.yellow {
    color: #ff8b00;
  }
}
.unset-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #fb4e62;
  margin-left: 5px;
}
.password-item {
  width: 40px;
  height: 6px;
  background: #eff0f2;
  margin-left: 5px;
  &.red {
    background: #f6625e;
  }
  &.yellow {
    background: #ff8b00;
  }
  &.green {
    background: #45dd90;
  }
}
.info-margin {
  margin-bottom: 10px;
}
.dialog-box {
  ::v-deep .el-dialog__header {
    display: none;
  }
}
.margin-t25 {
  margin-top: 25px;
}
.avatar-uploader {
  ::v-deep .el-upload {
    text-align: left;
  }
}
.avatar {
  width: 34px;
  height: 34px;
  margin-right: 10px;
  border-radius: 50%;
}
.tips {
  color: #b4b4b4;
  font-size: 10px;
}

</style>
