<!--
 * @Author: your name
 * @Date: 2020-12-21 13:57:26
 * @LastEditTime: 2022-03-23 10:35:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory_vue/src/views/user/BindMobile.vue
-->
<template>
  <div class="container">
    <div class="common-padding flex">
      <div class="font-size-large font-weight"><span class="safety">账号安全</span>-绑定手机号</div>
      <div class="back" @click="onBack">
        <img class="back-img" src="../../assets/user/icon_back.png" alt="">返回上一级
      </div>
    </div>
    <el-card class="card">
      <div class="content">
        <div class="title">
          <div class="font-size-large fir-title">
            绑定手机号
          </div>
          <div class="sec-title">
            请填写您常用的手机号，避免丢失或停用后频繁更换
          </div>
        </div>
        <el-form label-width="80px" :model="bindPhone" :rules="bindPhoneRules" ref="bindPhone">
          <el-form-item label="手机号码" prop="mobile" class="mobile">
            <el-input v-model="bindPhone.mobile" maxlength='11' minlength='11'></el-input>
          </el-form-item>
          <div class="inline" :inline="true">
            <el-form-item label="验证码" prop="code">
              <el-input v-model="bindPhone.code" class="code"></el-input>
              <el-button type="primary" v-if='countDownTime == 0' @click="sendSms" class="get-code"
                         :class="{'disable': !isPhoneNumber && isSend }">{{ isSend ? '发送中' : '获取验证码' }}
              </el-button>
              <el-button type="primary" class="get-code" v-else>{{ countDownTime }}s重新获取</el-button>
              <div class="voice-code" @click='sendVoice' v-show='showVoice'>收不到验证码？试试语音验证</div>
            </el-form-item>
          </div>
          <el-form-item>
            <el-button type="primary" @click="submitForm('bindPhone')" class="bind">确认绑定</el-button>
          </el-form-item>
        </el-form>
        <el-dialog
          width="25%"
          :visible.sync="failDialogVisible"
          :center='true'
          :show-close='false'
          top='30vh'
          class='dialog-box'
        >
          <div class="flex level-center">
            <img src="@/assets/common/icon_failed.png" alt="">
          </div>
          <div class="text-ct margin-t25">{{ passwordErrorModalMsg }}</div>
          <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onCloseFailDialogVisible">我知道了</el-button>
      </span>
        </el-dialog>
        <el-dialog
          width="25%"
          :visible.sync="successDialogVisible"
          :center='true'
          :show-close='false'
          :close-on-click-modal='false'
          top='30vh'
          class='dialog-box'
        >
          <div class="flex level-center">
            <img src="@/assets/common/icon_success.png" alt="">
          </div>
          <div class="text-ct margin-t25">绑定成功，可以用此手机号登录</div>
          <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onCloseSuccessDialogVisible">我知道了</el-button>
      </span>
        </el-dialog>
      </div>

    </el-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { initTCaptcha } from "@/utils/util";
import { getPersonInfo, setPersonInfo } from "@/utils/auth";

export default {
  name: 'BindMobile',
  data() {
    return {
      bindPhone: {
        mobile: '',
        code: ''
      },
      bindPhoneRules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
      },
      failDialogVisible: false,
      successDialogVisible: false,
      isSend: false,
      isVoice: false,
      countDownTime: 0,
      showVoice: false,
      smsTimer: null,
      passwordErrorModalMsg: '',
    }
  },
  computed: {
    ...mapGetters(['captchaAppId']),
    isPhoneNumber() {
      return /^\d{11}$/gi.test(this.bindPhone.mobile)
    },
  },
  components: {
  },
  methods: {
    ...mapActions(['getSmsCode', 'bindMobile','checkCaptcha']),
    onBack() {
      this.$router.go(-1)
    },
    onCloseFailDialogVisible() {
      this.failDialogVisible = false
    },
    onCloseSuccessDialogVisible() {
      this.successDialogVisible = false
      this.$router.push({ name: 'Index' })
    },
    sendSms(data) {
      if (!this.bindPhone.mobile)
        return
      if (!this.isPhoneNumber) {
        return this.$message.error('手机号码不正确')
      }
      if (getPersonInfo().mobile) {
        this.failDialogVisible = true
        this.passwordErrorModalMsg = '当前手机号已绑定其他账号，请更换其他手机号，或登录已绑定的账号解绑此手机'
        return false
      }
      this.isVoice = false
      this.getVerifyCode(data)
    },
    sendVoice(data) {
      if (!this.bindPhone.mobile) return this.$message.error('请输入手机号')
      if (!this.isPhoneNumber) {
        return this.$message.error('手机号码不正确')
      }
      if (this.isVoice) return
      this.isVoice = true
      this.clearSmsTimer()
      this.getVerifyCode(data)
    },
    getVerifyCode(data) {
      if (this.countDownTime) return
      if (!this.bindPhone.mobile) {
        return this.$message.error('请输入手机号')
      }
      if (!this.isPhoneNumber) {
        return this.$message.error('手机号码不正确')
      }
      if (this.isSend) {
        return
      }
      this.isSend = true
      let req = {
        mobile: this.bindPhone.mobile,
        clientType: 30,//1 医生端 2 代表端 30 厂家组织核心
        smsType: 106,//101 注册验证码 102 重置密码 103 更换手机号 104 推流端验证码 105厂家端登录验证码 106厂家端绑定手机获取验证码 107找回密码获取验证码
        isVoice: this.isVoice,
      }
      this.getSmsCode(req)
        .then(res => {
          this.$message.success('验证码已发送')
          this.isSend = false
          this.doIntervalCount()
        })
        .catch(rea => {
          this.isSend = false
          if (rea.code === 4000){
            if (this.isVoice){
              this.createCaptcha(this.sendVoice)
            }else {
              this.createCaptcha(this.sendSms)
            }
          }else {
            this.$message.error(rea.message)
          }
        })
    },
    doIntervalCount() {
      this.countDownTime = 60
      this.smsTimer = setInterval(() => {
        this.countDownTime--
        if (this.countDownTime < 30) {
          this.showVoice = true
        }
        if (this.countDownTime < 1) {
          this.isVoice = false
          this.clearSmsTimer()
        }
      }, 1000)
    },
    clearSmsTimer() {
      this.countDownTime = 0
      this.smsTimer && clearInterval(this.smsTimer)
      this.smsTimer = null
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handleSmsSubmit()
        } else {
          return false
        }
      });
    },
    handleSmsSubmit() {
      if (this.loading) return
      this.loading = true
      let req = {
        mobile: this.bindPhone.mobile,
        smsCaptcha: this.bindPhone.code,
      }
      this.bindMobile(req).then(res => {
        this.loading = false
        this.successDialogVisible = true
        this.handleSuccess(res)
      }, rea => {
        this.loading = false
        if (rea.code === 4020) {
          this.failDialogVisible = true
          this.passwordErrorModalMsg = rea.message
        } else {
          this.$message.error(rea.message)
        }
      })
    },
    handleSuccess(res){
      let info = getPersonInfo()
      info.mobile = res.mobile
      info.wxName = res.wxName
      info.wxAvatar = res.wxAvatar
      info.personToken = res.personToken
      info.openId = res.openId
      info.accountNum = res.accountNum
      setPersonInfo(info)
      this.$store.dispatch('getPermission')
    },
    createCaptcha(cb,faii){
      let captcha1 = new TencentCaptcha(this.captchaAppId, (res)=>{
        if(res.ret === 0){
          // 成功 处理回调
          this.onCheckCaptcha(res).then(reason=>{
            cb && cb(res)
          })
        }else if(res.ret === 2){
          // 失败
          faii && faii()
        }
      })
      captcha1.show()
    },
    onCheckCaptcha(data){
      let req = {
        randStr:data.randstr,
        ticket:data.ticket,
        mobile:this.bindPhone.mobile
      }
      return new Promise((resolve,reject)=>{
        this.checkCaptcha(req).then(res=>{
          resolve()
        },rea=>{
          this.$message.error(rea.message)
          reject()
        })
      })
    }
  },
  beforeCreate() {
    // 引入腾讯云sdk
    initTCaptcha()
  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
.container {
  padding: 0;

  .common-padding {
    position: relative;
    padding: 30px;
    background: white;
    border-bottom: 1px solid #f0f0f0;
  }

  .safety {
    color: #3D61E3;
  }

  .back {
    position: absolute;
    right: 70px;
    cursor: pointer;

    .back-img {
      width: 24px;
      height: 18.28px;
      margin-right: 10px;
      vertical-align: middle;
    }
  }

  .card {
    margin: 20px;
  }

  .content {
    width: 500px;
    height: 400px;
    margin: 30px auto;
  }

  .sec-title {
    margin: 7px 0 40px 0;
  }

  .mobile {
    width: 480px;
  }

  .code {
    width: 200px;
    margin-right: 20px;
  }

  .get-code {
    width: 180px;
    color: #3D61E3;
    background-color: #ffffff;
  }

  .voice-code {
    position: absolute;
    right: 20px;
    color: #3D61E3;
    cursor: pointer;
  }

  .bind {
    margin: 40px 0 0 70px;
    width: 180px;
  }

  .text-ct {
    padding-top: 20px;
  }

  .dialog-box >>> .el-dialog__header {
    display: none;
  }
}
</style>
