<template>
  <page-main title="切换账号">
    <div class="list" v-loading.fullscreen="loading">
      <el-row type="flex" class="flex-wrap">
        <el-col class="list-item pointer" :class="{ active: item.current }" v-for="item in factoryAccountList" :key="item.id">
          <div class="flex-between mgn-b10">
            <el-image :src="item.orgLogo.middle" v-if="item.orgLogo" class="avatar"></el-image>
            <div class="flex-box">{{ item.topOrgName }} - {{ item.orgName }} - {{ item.name }}</div>
            <div v-show="item.current" class="pdg-tb5"><span class="icon-circle"></span>当前账号</div>
            <div v-show="!item.current" class="change-btn" @click="onCheck(item)">切换到该账号</div>
          </div>
          <div class="data-item" :class="{ active: item.current }">
            <div>管理权限：{{ item.roleName }}</div>
            <div>最近登录时间：{{ item.lastLoginDate }}</div>
          </div>
        </el-col>
      </el-row>
    </div>
  </page-main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getUserInfo, getPersonToken, setPersonInfo } from '@/utils/auth'

export default {
  name: 'SelectFactory',
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(['factoryAccountList'])
  },
  methods: {
    ...mapActions(['getFactoryAccountList', 'onCheckFactoryAccount', 'setNormalAccount', 'userSetUserInfo']),
    init() {
      this.loading = true
      let req = {
        personToken: getPersonToken()
      }
      this.getFactoryAccountList(req).then(
        res => {
          this.loading = false
        },
        rea => {
          this.loading = false
        }
      )
    },
    onCheck(item) {
      this.onCheckFactoryAccount(item)
      if (this.loading) return
      let checkItem = {}
      checkItem = this.factoryAccountList.filter(item => item.current)[0]
      if (!checkItem.id) return this.$message.error('请选择切换的账号')
      let req = {
        userId: checkItem.id,
        personToken: getPersonToken()
      }
      this.loading = true
      this.setNormalAccount(req).then(
        res => {
          // 设置默认厂家之后的操作
          setPersonInfo(res)
          this.$store.commit('SET_USER_INFO', getUserInfo())
          this.loading = false
          window.location.replace('/index')
        },
        rea => {
          this.loading = false
          this.$message.error(rea.message)
        }
      )
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.el-button {
  width: 150px;
}

.list {
  margin: 10px;
}

.list-item {
  color: #272e40;
  font-size: 12px;
  padding: 15px !important;
  background: #fafafa;
  border-radius: 2px;
  margin: 10px;
  max-width: 320px;

  &.active {
    color: $color-primary;
    background: rgba(25, 140, 255, 0.12);
  }
}

.flex-box {
  font-weight: bold;
  font-size: 14px;
  align-items: center;
}

.data-item {
  color: #5a5a5a;

  &.active {
    color: $color-primary;

    .list-item-radio {
      background: $color-primary;
      border: 0;
    }
  }
}

.icon-circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #30c691;
  margin-right: 5px;
}

.change-btn {
  color: #969696;
  border: 1px solid #c8c8c8;
  padding: 5px 10px;
  background: #ffffff;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}
</style>
