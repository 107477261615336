<!--
 * @Author: your name
 * @Date: 2020-12-24 14:44:50
 * @LastEditTime: 2021-01-12 10:28:05
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory_vue/src/views/user/components/SensitiveRecordChild.vue
-->
<template>
  <div class="table">
    <el-table v-loading="loading" :data="accountSensitiveRecord" stripe size="small">
      <el-table-column prop="login" align="left" label="操作账号" fixed> </el-table-column>
      <el-table-column prop="department" align="center" label="组织角色"> </el-table-column>
      <el-table-column prop="roleId" align="center" label="账号权限">
        <template slot-scope="scope">
          <span>{{ filterRoleName(scope.row.roleId) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="loginType" align="center" label="登录方式">
        <template slot-scope="scope">
          <span>{{ filterLoginType(scope.row.loginType) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="loginLocation" align="center" label="登录地址"> </el-table-column>
      <el-table-column prop="content" align="center" label="操作内容"> </el-table-column>
      <el-table-column prop="operatedTime" align="right" label="登录时间"> </el-table-column>
    </el-table>
    <el-row type="flex" justify="center" class="t-p">
      <el-pagination background hide-on-single-page layout="prev, pager, next" :page-size="pageSize" :current-page="pageNo" @current-change="onChange" :total="totalCount" :small="isMobile"> </el-pagination>
    </el-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { isMobile } from '@/utils/util.js'
export default {
  name: 'SensitiveRecordChild',
  data() {
    return {
      loading: false,
      pageNo: 1,
      pageSize: 20,
      isEnd: false,
      totalCount: 0,
      isMobile: isMobile()
    }
  },
  components: {},
  filters: {},
  computed: {
    ...mapGetters(['accountSensitiveRecord', 'loginTypeData', 'permissionRoles']),
  },
  methods: {
    ...mapActions(['getSensitiveRecord', 'getAccountsRoles']),
    onChange(pageNo) {
      this.pageNo = pageNo
      this.init()
    },
    init() {
      this.loading = true
      let req = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }
      this.getSensitiveRecord(req).then(
        (res) => {
          this.loading = false
          this.totalCount = res.totalCount
        },
        (rea) => {
          this.loading = false
          this.$message.error(rea.message)
        }
      )
    },
    filterRoleName(val) {
      let name = ''
      this.permissionRoles.map((item) => {
        if (item.id == val) {
          name = item.name
        }
      })
      return name
    },
    filterLoginType(val) {
      return this.loginTypeData[val]
    },
  },
  mounted() {
    this.init()
    !this.getAccountsRoles.length && this.getAccountsRoles()
  },
}
</script>
<style lang="scss" scoped>
.table >>> thead th {
  height: 80px;
  font-size: 14px;
}
.t-p {
  padding-top: 15px;
}
.table >>> .el-table .cell {
  color: #272e40;
  font-size: 14px;
}
</style>
