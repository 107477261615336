<!--
 * @Author: your name
 * @Date: 2020-12-21 13:57:26
 * @LastEditTime: 2020-12-21 14:05:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory_vue/src/views/user/ChangePwd.vue
-->
<template>
  <div>
    <page-main back title>
      <div class="content">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="旧密码" prop="oldPassword">
            <el-input type="password" v-model="ruleForm.oldPassword" autocomplete="off" class="input-width"></el-input>
          </el-form-item>
          <div class="inline" :inline="true">
            <div class="password-strength">
              <div class="strength danger" :class="{'modes-high': modes === 0}">高危</div>
              <div class="strength low" :class="{'modes-weak': modes === 1}">弱</div>
              <div class="strength middle" :class="{'modes-middle': modes ===2}">中</div>
              <div class="strength high" :class="{'modes-strong': modes === 3}">强</div>
            </div>
            <el-form-item label="新密码" prop="password">
              <el-input type="password" v-model="ruleForm.password" autocomplete="off" class="input-width" @input="descInput"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="重复新密码" prop="checkPassword">
            <el-input type="password" v-model="ruleForm.checkPassword" autocomplete="off"
                      class="input-width"></el-input>
          </el-form-item>
          <div class="tip-message" :class="{'message-high': isShowTip}">密码不少于8位，必须包含字母</div>
          <el-form-item>
            <el-button type="primary" class="bind" @click="submit('ruleForm')">确认修改</el-button>
            <div class="changeByMobile">忘记旧密码？<span @click="changeByMobile">使用手机短信验证，</span>或联系客服13758257847</div>
          </el-form-item>
        </el-form>
        <el-dialog
          width="25%"
          :visible.sync="failDialogVisible"
          :center='true'
          :show-close='false'
          top='30vh'
          class='dialog-box'
        >
          <div class="flex level-center">
            <img src="@/assets/common/icon_failed.png" alt="">
          </div>
          <div class="text-ct margin-t25">{{ passwordErrorModalMsg }}</div>
          <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onCloseFailDialogVisible">我知道了</el-button>
      </span>
        </el-dialog>
        <el-dialog
          width="25%"
          :visible.sync="successDialogVisible"
          :center='true'
          :show-close='false'
          top='30vh'
          class='dialog-box'
        >
          <div class="flex level-center">
            <img src="@/assets/common/icon_success.png" alt="">
          </div>
          <div class="text-ct margin-t25">恭喜您密码修改成功</div>
          <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onCloseSuccessDialogVisible">完成</el-button>
      </span>
        </el-dialog>
      </div>
    </page-main>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { getPersonInfo } from "@/utils/auth";
import {cryptoEncrypt, checkPassWordStrength} from '@/utils/util'

export default {
  name: 'ChangePwd',
  data() {
    const checkOldPass = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('原密码不能为空'));
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPassword !== '') {
          this.$refs.ruleForm.validateField('checkPassword');
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        oldPassword: '',
        password: '',
        checkPassword: ''
      },
      rules: {
        oldPassword: [
          { validator: checkOldPass, trigger: 'blur' }
        ],
        password: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPassword: [
          { validator: validatePass2, trigger: 'blur' }
        ]
      },
      failDialogVisible: false,
      successDialogVisible: false,
      passwordErrorModalMsg: '',
      modes: -1,
      isShowTip: false,
    }
  },
  watch:{
    'ruleForm.password'(){
      this.isShowTip = false
      if (this.ruleForm.password.length < 8) {
        this.isShowTip = true
      }
      if (this.modes === 0) {
        this.isShowTip = true
      }
    }
  },
  methods: {
    ...mapActions(['changePwd']),
    descInput() {
      this.modes = checkPassWordStrength(this.ruleForm.password).key
    },
    onCloseFailDialogVisible() {
      this.failDialogVisible = false
      this.ruleForm.oldPassword = ''
      this.ruleForm.password = ''
      this.ruleForm.checkPassword = ''
      this.modes = -1
    },
    onCloseSuccessDialogVisible() {
      this.successDialogVisible = false
      this.$router.push({ name: 'Index' })
    },
    changeByMobile() {
      if (getPersonInfo().mobile === null) {
        this.failDialogVisible = true
        this.passwordErrorModalMsg = '您还未绑定手机号，请使用旧密码验证或联系客服处理'
      } else{
        this.$router.push({ name: 'ChangePassword' })
      }
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitForm()
        } else {
          return false
        }
      });
    },
    submitForm() {
      if (this.ruleForm.password.length < 8) {
        return this.$message.error('新密码不能低于8位')
      }
      if (this.ruleForm.password.length > 20) {
        return this.$message.error('新密码不能高于20位')
      }
      if (this.modes === 0) {
        return this.$message.error('新密码必须包含数字和字母')
      }
      let req = {
        oldPassword: cryptoEncrypt(this.ruleForm.oldPassword),
        password: cryptoEncrypt(this.ruleForm.password),
        checkPassword: cryptoEncrypt(this.ruleForm.checkPassword),
        pwdSecurityDegree: this.modes
      }
      this.changePwd(req).then(res => {
        this.successDialogVisible = true
      }, rea => {
        this.failDialogVisible = true
        this.passwordErrorModalMsg = rea.message
      })
    }
  },
  mounted() {
  }
}
</script>
<style lang="scss" scoped>
.back {
  position: absolute;
  right: 70px;
  cursor: pointer;

  .back-img {
    width: 24px;
    height: 18.28px;
    margin-right: 10px;
    vertical-align: middle;
  }
}

.content {
  width: 700px;
  height: 400px;
  margin: 30px auto;
}

.input-width {
  width: 400px;
}

.bind {
  margin: 50px 0 30px 90px;
  width: 180px;
}

.text-ct {
  padding-top: 20px;
}

.dialog-box >>> .el-dialog__header {
  display: none;
}

.changeByMobile {
  color: #909399;
  cursor: pointer;

  span {
    color: #3D61E3;
  }
}

.strength {
  display: inline-block;
  background-color: #ddd;
  width: 40px;
  height: 16px;
  font-size: 12px;
  color: #ffffff;
  margin: 12px 3px 0 0;
  padding-left: 14px;

  &.danger {
    padding-left: 8px;
  }
}

.password-strength {
  float: right;
  height: 12px;

  .strength-color {
    background-color: #F6625E;
  }

  .modes-high {
    background-color: #F6625E;
  }

  .modes-weak {
    background-color: #FF8B00;
  }

  .modes-middle {
    background-color: #FFD200;
  }

  .modes-strong {
    background-color: #09BB07;
  }
}

.tip-message {
  margin-left: 100px;
  color: #92959b;

  .message-high {
    color: #F6625E;
  }
}
</style>