<!--
 * @Author: your name
 * @Date: 2020-12-21 14:50:04
 * @LastEditTime: 2022-03-23 10:35:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory_vue/src/views/user/BindWechat.vue
-->
<template>
  <div class="container">
    <div class="common-padding flex">
      <div class="font-size-large font-weight"><span class="safety">账号安全</span>-绑定微信号</div>
      <div class="back" @click="onBack">
        <img class="back-img" src="../../assets/user/icon_back.png" alt="">返回上一级
      </div>
    </div>
    <el-card class="card">
      <div class="content">
        <div class="step">
          <div class="steps step-one"></div>
          <div class="steps step-two" :class="{'this-step': step != 1}"></div>
          <div class="steps step-three" :class="{'this-step': step === 3}"></div>
        </div>
        <div class="tips">
          <div class="tip one-tip">1.微信扫码</div>
          <div class="tip" :class="{'one-tip': step != 1}">2.确认信息</div>
          <div class="tip" :class="{'one-tip': step === 3}">3.完成绑定</div>
        </div>
        <div class="scan-box flex flex-column vertical-center" v-if="step === 1">
          <div class="scan-image flex level-center pointer" @click="initScan">
            <img :src="wxCodeImage.qrCodeUrl" alt="">
          </div>
          <div class="font-size-normal">扫码绑定微信</div>
          <div class="flex level-center vertical-center scan-icon-box">
            <div class="flex vertical-center scan-icon-item">
              <img src="@/assets/user/icon_fast.png" alt="" class="scan-icon">
              <div class="icon-text">更便捷</div>
            </div>
            <div class="flex vertical-center">
              <img src="@/assets/user/icon_safe.png" alt="" class="scan-icon">
              <div>更安全</div>
            </div>
          </div>
        </div>
        <div class="scan-box vertical-center" v-if="step === 2">
          <div class="sure-bind flex level-center">
            <div class="fac-image">
              <img src="@/assets/user/icon_default.png" alt="">
              <div class="factory-name">厂家：{{ userInfo.orgName }}<br>
                <span>名称：{{userInfo.name}} <br>账号：{{ userInfo.login }}</span></div>
            </div>
            <img src="../../assets/user/icon_bind.png" alt="" class="img-bind">
            <div class="wx-image">
              <img :src="isWxAvatar" alt="">
              <div class="desc-two">昵称：{{ wxName }}</div>
            </div>
          </div>
          <div class="flex level-center vertical-center scan-icon-box">
            <div class="flex vertical-center">
              <el-button type="primary" class="back-step next-step" @click="goBack">使用其他微信号</el-button>
              <el-button type="primary" class="next-step" @click="submitBindWx">确认绑定</el-button>
            </div>
          </div>
        </div>
        <div class="scan-box flex flex-column vertical-center" v-if="step === 3">
          <img src="../../assets/user/icon_success.png" alt="" class="success-img">
          <div class="font-size-normal">绑定成功，可以使用此微信登录当前账号</div>
          <div class="flex level-center vertical-center scan-icon-box">
            <div class="flex vertical-center">
              <el-button type="primary" class="next-step" @click="finish">完成</el-button>
            </div>
          </div>
        </div>
        <el-dialog
          width="25%"
          :visible.sync="failDialogVisible"
          :center='true'
          :show-close='false'
          top='30vh'
          class='dialog-box'
        >
          <div class="flex level-center">
            <img src="@/assets/common/icon_failed.png" alt="">
          </div>
          <div class="text-ct margin-t25">此微信已绑定其他账号，
            <br>请更换其他微信扫码，<br>或登录已绑定的账号解绑此手机
          </div>
          <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onCloseFailDialogVisible">我知道了</el-button>
      </span>
        </el-dialog>
      </div>
    </el-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { getUserInfo, getPersonInfo, setPersonInfo } from "@/utils/auth";

export default {
  name: 'BindWechat',
  data() {
    return {
      step: 1,
      failDialogVisible: false,
      scanTimer: null,//扫码登录查询结果定时器
      isReqScanResult: false,//扫码结果查询限制
      personInfo: '',
      userInfo: '',
      wxAvatar: '',
      wxName: '',
    }
  },
  computed: {
    ...mapGetters(['wxCodeImage']),
    isWxAvatar() {
      return this.wxAvatar ? this.wxAvatar : require('@/assets/user/icon_default.png')
    }
  },
  components: {},
  methods: {
    ...mapActions(['getWxCodeImage', 'checkWxCodeResult', 'bindWechat', 'getPermission']),
    onBack() {
      this.$router.go(-1)
    },
    initScan() {//获取扫码登录二维码
      this.clearScanTimer()
      let req = {
        codeType: 2
      }
      this.getWxCodeImage(req).then(res => {
        this.startScanInterval()
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    startScanInterval() {//查询扫码登录结果轮训
      this.scanTimer = setInterval(() => {
        let req = {
          loopKey: this.wxCodeImage.loopKey,
        }
        this.checkWxCodeResult(req).then(res => {
          // 状态：SUCCESS 扫码成功，NO_USER 没找到用户；WAIT_FOR_SCAN 等待扫码; QRCODE_EXPIRED 二维码过期; NO_PERMISSION 不是医生、没认证、没直播等 ; NOT_BIND 未绑定账号；NOT_THIS_WECHAT 不是当前微信
          if (res.status === 'SUCCESS') {
            this.openId = res.data.openId
            this.wxAvatar = res.data.wxAvatar
            this.wxName = res.data.wxName
            this.clearScanTimer()
            this.step = 2
          } else if (res.status === 'QRCODE_EXPIRED') {
            this.initScan()
          } else if (res.status !== 'WAIT_FOR_SCAN') {
            this.$message.error(res.status)
            this.clearScanTimer()
          }
        }, rea => {
          this.clearScanTimer()
          this.$message.error(rea.message)
        })
      }, 3000)
    },
    clearScanTimer() {
      this.scanTimer && clearInterval(this.scanTimer)
      this.scanTimer = null
    },
    goBack() {
      this.step = 1
      this.initScan()
    },
    submitBindWx() {
      let req = {
        openId: this.openId,
        isChange: false
      }
      this.bindWechat(req).then(res => {
        this.handleSuccess()
        this.step = 3
      }, rea => {
        if (rea.code === 4004) {
          this.failDialogVisible = true
        } else {
          this.$message.error(rea.message)
        }
      })
    },
    handleSuccess() {
      let info = getPersonInfo()
      info.openId = this.openId
      info.wxAvatar = this.wxAvatar
      info.wxName = this.wxName
      setPersonInfo(info)
      this.$store.dispatch('getPermission')
    },
    finish() {
      this.$router.push({ name: 'Index' })
    },
    onCloseFailDialogVisible() {
      this.failDialogVisible = false
      this.step = 1
      this.initScan()
    },
  },
  mounted() {
    this.initScan()
    this.userInfo = getUserInfo()
    this.personInfo = getPersonInfo()
  }
}
</script>
<style lang="scss" scoped>
.container {
  padding: 0;
}

.common-padding {
  position: relative;
  padding: 30px;
  background: white;
  border-bottom: 1px solid #f0f0f0;
}

.safety {
  color: #3D61E3;
}

.back {
  position: absolute;
  right: 70px;
  cursor: pointer;

  .back-img {
    width: 24px;
    height: 18.28px;
    margin-right: 10px;
    vertical-align: middle;
  }
}

.card {
  margin: 20px;
}

.content {
  width: 600px;
  height: 400px;
  margin: 30px auto;
}

.step {
  width: 700px;
}

.steps {
  display: inline-block;
  width: 200px;
  height: 15px;
}

.step-one {
  margin-right: 2px;
  border-radius: 8px 0 0 8px;
  background-color: #3D61E3;
}

.this-step {
  background-color: #3D61E3 !important;
}

.step-two {
  background-color: #EFF0F2;
  margin-right: 2px;
}

.step-three {
  background-color: #EFF0F2;
  border-radius: 0 8px 8px 0;
}

.scan-box {
  padding-top: 35px;
  padding-bottom: 60px;

  .scan-image {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 20px;

    img {
      width: 200px;
      height: 200px;
    }
  }

  .scan-icon-box {
    margin-top: 28px;
    margin-bottom: 38px;

    .scan-icon {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }

    .icon-text {
      margin-right: 10px;
    }
  }
}

.next-step {
  width: 180px;
}

.tip {
  width: 200px;
  padding-top: 10px;
  padding-left: 60px;
  display: inline-block;

  .one-tip {
    color: #3D61E3;
  }
}

.back-step {
  background-color: #ffffff;
  color: #3D61E3;
}

.remarks {
  font-size: 14px;
  color: #C8C9CE;
}

.sure-bind {
  position: relative;
  margin: 50px 0 150px 0;

  .wx-image {
    img {
      position: absolute;
      width: 70px;
      height: 70px;
      right: 100px;
      border-radius: 50%;
    }
  }

  .fac-image {
    img {
      position: absolute;
      width: 70px;
      height: 70px;
      left: 100px;
      border-radius: 50%;
    }
  }

  .desc-two {
    position: absolute;
    margin-left: 60px;
    top: 90px;
  }

  .factory-name {
    position: absolute;
    left: 70px;
    top: 90px;
  }

  .img-bind {
    width: 30px;
    height: 18px;
    margin: 30px 50px 0 50px;
  }

  .success-img {
    width: 80px;
    height: 80px;
    margin: 40px;
  }

  .font-size-normal {
    text-align: center;
  }
}

.dialog-box >>> .el-dialog__header {
  display: none;
}

.text-ct {
  padding-top: 20px;
}
</style>
